import HttpClient from './http-clients/portaljad'

const baseUrl = 'empresa'

export default class EmpresaService {
  static async verificaOnline(empresaId) {
    try {
      if (!empresaId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, 'verifica-online', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async switchOnline(empresaId) {
    try {
      if (!empresaId) {
        return undefined
      }
      const { data } = await HttpClient.put([baseUrl, 'switch-online', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}
