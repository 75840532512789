<template>
  <div>
    <b-card 
      no-body 
      class="mb-2"
    >
      <div class="m-2">
        <validation-observer #default="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-row >
              <b-col md="6">
                <b-form-group
                  label="Selecione um dos relatórios e o período"
                  label-for="tipoRelatorio"
                >
                  <v-select
                    id="tipoRelatorio"
                    v-model="tipoRelatorio"
                    :options="tiposDeRelatorioOptions"
                    placeholder="Selecione um Relatório"
                    :reduce="val=>val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row 
              align-v="center"
            >
              <b-col cols="6">
                <validation-provider 
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-checkbox
                  v-if="tipoRelatorio.includes('MOVIMENTO')"
                  v-model="somenteDatasSemMovimento"
                  switch
                >
                  <span class="pl-1">Considerar somente datas sem movimento</span>
                </b-form-checkbox>
              </b-col>
              <b-col 
                cols="2"
                class="d-flex justify-content-end"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  Pesquisar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <b-card
      no-body 
      class="mb-2"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              placeholder="Buscar..."
              debounce="500"
            />
          </b-col>
          <b-col
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>movimentos por página</label>
          </b-col>
          <b-col 
            cols="12"
            md="2"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="gerarExcel(tipoRelatorio, dateRange.startDate, dateRange.endDate)"
            >
              <span class="text-nowrap">Exportar Excel</span>
            </b-button>
          </b-col>
          <b-col 
            cols="12"
            md="2"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="secondary"
              @click="generatePdf(tipoRelatorio, dateRange.startDate, dateRange.endDate)"
            >
              <span class="text-nowrap">Exportar PDF</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card 
      no-body 
      class="mb-2"
    >
      <div class="m-2">
        <b-table
          ref="tableRef"
          show-empty
          :items="dados"
          :fields="headerTable[tipoRelatorio]"
          :filter="search"
          :per-page="perPage"
          :current-page="currentPage"
          sticky-header
          :empty-text="carregando ? 'Carregando...' : 'Nenhum dado encontrado'"
          empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
          :sort-compare="comparaClientes"
          @filtered="onFiltered"
        >
          <template #cell(numeroDocumento)="data">
            <span>{{ data.item.numeroDocumento ? maskCnpj(data.item.numeroDocumento) : '' }}</span>
          </template>
          <template
            #cell(documento)="data"
          >
            <span>{{ data.item.documento ? maskCpf(data.item.documento) : '' }}</span>
          </template>
          <template
            #cell(dataEnvio)="data"
          >
            <span>{{ data.item.dataEnvio ? maskDate(data.item.dataEnvio) : '' }}</span>
          </template>
          <template 
            #cell(detalhes)="data" 
          >
            <b-button
              variant="link"
              @click="openModal(data)"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
          </template>
          <template
            #cell(valor)="data"
          >
            <span>{{ maskCurrency(data.item.valor) }}</span>
          </template>
          <template
            #cell(valorTotalTaxado)="data"
          >
            <span>{{ maskCurrency(data.item.valorTotalTaxado) }}</span>
          </template>
          <template
            v-if="tipoRelatorio.includes('MOVIMENTO') || tipoRelatorio.includes('VENDAS')"
            slot="bottom-row"
          >
            <td/>
            <td class="table-footer">
              TOTAL
            </td>
            <td class="table-footer">
              {{ totalSoma('quantidade') }}
            </td>
            <td class="table-footer">
              {{ maskCurrency(totalSoma('valor')) }}
            </td>
          </template>
        </b-table>
        <div class="m-2">
          <b-row v-if="totalRows > 0">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando do 
                {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                  totalRows
                }} resultados</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <b-modal
      ref="modal"
      title="Relatorio Login"
      ok-only
      ok-title="OK"
      :size="tipoRelatorio === 'LOGIN_USUARIOS_UNIDADE' ? 'md' : 'lg'"
      style="max-height: 1000px"
    >
      <login-modal 
        :dados="modalData"
        :relatorio="tipoRelatorio === 'LOGIN_USUARIOS_UNIDADE' ? 'UNIDADE': 'CLIENTE' "
      />

    </b-modal>
  </div>
</template>

<script>
import { reactive, watch, toRefs, ref, onUnmounted } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import DateRange from '@/components/form/DateRange.vue'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import moment from 'moment'
import utils from '@/helpers/utils'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ExcelComposition from '@/components/exportar/excel.composition'
import LoginModal from './components/login-modal.vue'

export default {
  name: 'Relatorios',
  components: { DateRange, LoginModal },
  setup() {
    const { appCarregando } = $themeConfig.app
    const overviewStore = createNamespacedHelpers('overview')
    const { limparDados } = overviewStore.useActions(['limparDados'])
    const modal = ref(null)
    const tableRef = ref(null)
    
    const state = reactive({
      dateRange: {
        startDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        endDate: utils.dateRangeComTimeZone(),
      },
      dados: overviewStore.useGetters(['dadosRelatorio']).dadosRelatorio,
      dadosLogin: overviewStore.useGetters(['dadosLogin']).dadosLogin,
      tipoRelatorio: '',
      modalData: {},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
      reversaCliente: false,
      somenteDatasSemMovimento: false,
    })

    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    watch(() => state.dados, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    watch(() => state.tipoRelatorio, value => {
      if (value) {
        limparDados()
      }
    })

    onUnmounted(() => {
      limparDados()
    })

    const tiposDeRelatorioOptions = [
      { label: 'Vendas - Cliente por Período', value: 'VENDAS_CLIENTE_PERIODO' },
      { label: 'Login - Usuários Cliente', value: 'LOGIN_USUARIOS_CLIENTE' },
      { label: 'Login - Usuários Unidade', value: 'LOGIN_USUARIOS_UNIDADE' },
      { label: 'Movimentos - Quantidade e valor', value: 'MOVIMENTO_QUANTIDADE_VALOR' },
    ]

    const tiposDeRelatoriosDictionary = { 
      VENDAS_CLIENTE_PERIODO: 'Vendas - Cliente por Período',
      LOGIN_USUARIOS_CLIENTE: 'Login - Usuários Cliente',
      LOGIN_USUARIOS_UNIDADE: 'Login - Usuários Unidade',
      MOVIMENTO_QUANTIDADE_VALOR: 'Movimentos - Quantidade e valor',
    }

    const headerTable = {
      VENDAS_CLIENTE_PERIODO: [
        { key: 'clienteId', label: 'Cod', sortable: true },
        { key: 'numeroDocumento', label: 'Documento', sortable: true },
        { key: 'clienteNome', sortable: true, label: 'Nome Cliente', tdClass: 'text-nowrap' },
        { key: 'quantidade', sortable: true },
        { key: 'valor', label: 'Total', sortable: true },
      ],

      LOGIN_USUARIOS_CLIENTE: [
        { key: 'id', label: 'id usuário', sortable: true },
        { key: 'nome', sortable: true, label: 'Nome', tdClass: 'text-nowrap' },
        { key: 'documento', label: 'Documento', sortable: true },
        { key: 'email', lavel: 'Email Usuário', sortable: true },
        { key: 'totalLogin', label: 'Total Login', sortable: true },
        { key: 'detalhes' },
      ],
      LOGIN_USUARIOS_UNIDADE: [
        { key: 'id', label: 'id usuário', sortable: true },
        { key: 'nome', sortable: true, label: 'Nome', tdClass: 'text-nowrap' },
        { key: 'documento', label: 'Documento', sortable: true },
        { key: 'email', lavel: 'Email Usuário', sortable: true },
        { key: 'totalLogin', label: 'Total Login', sortable: true },
        { key: 'detalhes' },
      ],
      MOVIMENTO_QUANTIDADE_VALOR: [
        { key: 'dataEnvio', label: 'Data', sortable: true, tdClass: 'text-nowrap' },
        { key: 'diaDaSemana', label: 'Dia da Semana', sortable: true },
        { key: 'quantidade', label: 'Quantidade Envios', sortable: true },
        { key: 'valor', label: 'Valor Total', sortable: true },
      ],
    }

    const sendRequest = filtros => {
      state.carregando = true
      store.dispatch('overview/relatorio', filtros).then(() => {
        state.carregando = false
      })
    }

    const table = {
      search: ref(''),
      sortBy: ref(''),
      isSortDirDesc: ref(false),
    }

    const onSubmit = () => {
      const { empresaId } = JSON.parse(localStorage.getItem('userName'))
      table.search.value = ''
      state.currentPage = 1
      const filtros = {
        dataInicial: state.dateRange.startDate,
        dataFinal: state.dateRange.endDate,
        empresaId,
        tipoRelatorio: state.tipoRelatorio,
        somenteDatasSemMovimento: state.somenteDatasSemMovimento,
        reversaCliente: state.reversaCliente,
      }
      if (filtros.tipoRelatorio === '') {
        swal.fire({
          icon: 'warning',
          title: 'Seleciona um tipo de relatório',
          showConfirmButton: false,
          timer: 2000,
        })
      } else {
        sendRequest(filtros)
      }
    }

    const { getValidationState } = formValidation()

    const separaValores = (obj, tipoSoma) => {
      if (tipoSoma === 'quantidade') {
        const value = obj.quantidade ? obj.quantidade : 0
        return Number(value)
      }
      if (tipoSoma === 'valor') {
        const value = obj.valor ? obj.valor : 0
        return Number(value)
      }
      return 0
    }

    const totalSoma = tipoSoma => {
      let total = 0
      if (tableRef && tableRef.value) {
        const array = tableRef.value.filteredItems
        array.forEach(obj => {
          const valor = separaValores(obj, tipoSoma)
          total += valor
        })
      }
      return total
    }

    const montarBodyExportar = tipoRelatorio => {
      if (tipoRelatorio === 'VENDAS_CLIENTE_PERIODO') {
        return tableRef.value.sortedItems.map(d => ({
          clienteId: d.clienteId,
          documento: d.numeroDocumento,
          nome: d.clienteNome,
          quantidade: d.quantidade,
          valor: Number(d.valor),
        }))
      }
      if (tipoRelatorio === 'LOGIN_USUARIOS_CLIENTE') {
        return state.dadosLogin.map(d => {
          let documento = ''
          if (d.documento != null && d.documento.length > 0) {
            documento = (d.documento != null && d.documento.length <= 12) ? utils.maskCpf(d.documento) : utils.maskCnpj(d.documento)
          }
          return {
            id: d.id,
            nome: d.nome,
            documento,
            dataHoraLogin: utils.maskDateHour(d.dataHoraLogin),
            email: d.email,
            localEntrada: d.localEntrada,
            totalLogin: d.totalLogin,
            ip: d.ip,
          }
        })
      }
      if (tipoRelatorio === 'LOGIN_USUARIOS_UNIDADE') {
        return state.dadosLogin.map(d => {
          let documento = ''
          if (d.documento != null && d.documento.length > 0) {
            documento = (d.documento != null && d.documento.length <= 12) ? utils.maskCpf(d.documento) : utils.maskCnpj(d.documento)
          }
          return {
            id: d.id,
            nome: d.nome,
            documento,
            totalLogin: d.totalLogin,
            dataHoraLogin: utils.maskDateHour(d.dataHoraLogin),
            email: d.email,
            detalhes: d.detalhes,
            ip: d.ip,
          }
        })
      }
      if (tipoRelatorio === 'MOVIMENTO_QUANTIDADE_VALOR') {
        return state.dados.map(d => ({
          dataEnvio: utils.maskDate(d.dataEnvio),
          diaDaSemana: d.diaDaSemana,
          quantidade: d.quantidade,
          valor: d.valor,
        }))
      }
      return {}
    }

    const { downloadSpreadsheetWithHeader } = ExcelComposition()
    const downloadExcel = (tipoRelatorio, dataInicio, dataFim) => {
      let dados = {}
      let heading = []
      if (tipoRelatorio === 'VENDAS_CLIENTE_PERIODO') {
        heading = [['Cod', 'Documento', 'Nome Cliente', 'Quantidade', 'Total']]
        dados = montarBodyExportar(tipoRelatorio)
      }
      if (tipoRelatorio === 'LOGIN_USUARIOS_CLIENTE') {
        heading = [['Id', 'Nome', 'Documento', 'Data Hora de Login', 'Email Usuário', 'Local de Login', 'Total de Login', 'ip']]
        dados = montarBodyExportar(tipoRelatorio)
      }
      if (tipoRelatorio === 'LOGIN_USUARIOS_UNIDADE') {
        heading = [['Id', 'Nome', 'Documento', 'Total de Login', 'Data Hora de Login', 'Email Usuário', 'ip']]
        dados = montarBodyExportar(tipoRelatorio)
      }
      if (tipoRelatorio === 'MOVIMENTO_QUANTIDADE_VALOR') {
        heading = [['Data Envio', 'Dia da Semana', 'Quantidade envios', 'Valor Total']]
        dados = montarBodyExportar(tipoRelatorio)
      }
      if (dados.length === 0) {
        appCarregando.hide()
        swal.fire({
          icon: 'warning',
          title: 'Sem dados para exportar',
          text: 'O Resultado da sua busca não retornou dados para exportação.',
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        downloadSpreadsheetWithHeader(tiposDeRelatoriosDictionary[tipoRelatorio], heading, dataInicio, dataFim, dados, tiposDeRelatoriosDictionary[tipoRelatorio])
      }
    }
    const gerarExcel = (tipoRelatorio, dataInicio, dataFim) => {
      appCarregando.show()
      setTimeout(() => {
        downloadExcel(tipoRelatorio, dataInicio, dataFim)
      }, 100)
    }

    const generatePdf = (tipoRelatorio, dataInicio, dataFim) => {
      let footersRows = []
      const footStyle = {
        fillColor: [155, 155, 155],
      }
      const periodo = `${dataInicio.split('-')[2]}/${dataInicio.split('-')[1]}/${dataInicio.split('-')[0]} a ${dataFim.split('-')[2]}/${dataFim.split('-')[1]}/${dataFim.split('-')[0]}`
      const generateData = () => {
        let dados = []
        let heading = []
        if (tipoRelatorio === 'VENDAS_CLIENTE_PERIODO') {
          footersRows = [[
            { content: '', datakey: 'clienteId', styles: footStyle },
            { content: 'Total', datakey: 'nome', styles: footStyle },
            { content: Number(totalSoma('quantidade')).toString(), datakey: 'quantidade', styles: footStyle },
            { content: utils.maskCurrency(Number(totalSoma('valor'))), datakey: 'valor', styles: footStyle },
          ]]
          heading = [{ clienteId: 'Cód.', nome: 'Nome Cliente', quantidade: 'Quantidade', valor: 'Valor' }]
          dados = tableRef.value.sortedItems.map(d => ({
            clienteId: Number(d.clienteId).toString(),
            nome: d.clienteNome,
            quantidade: Number(d.quantidade).toString(),
            valor: utils.maskCurrency(Number(d.valor)),
          }))
        }
        if (tipoRelatorio === 'LOGIN_USUARIOS_CLIENTE') {
          heading = [{ id: 'Id', nome: 'Nome', documento: 'Documento', totalLogin: 'Total de Login', dataHoraLogin: 'Data Hora', email: 'Email', localEntrada: 'Local', ip: 'ip' }]
          dados = montarBodyExportar(tipoRelatorio)
        }
        if (tipoRelatorio === 'LOGIN_USUARIOS_UNIDADE') {
          heading = [{ id: 'Id', nome: 'Nome', documento: 'Documento', totalLogin: 'Total de Login', dataHoraLogin: 'Data Hora de Login', email: 'Email', ip: 'ip' }]
          dados = montarBodyExportar(tipoRelatorio)
        }
        if (tipoRelatorio === 'MOVIMENTO_QUANTIDADE_VALOR') {
          heading = [{ dataEnvio: 'Data Envio', diaDaSemana: 'Dia da Semana', quantidade: 'Quantidade envios', valor: 'Valor Total' }]
          dados = montarBodyExportar(tipoRelatorio)
          footersRows = [[
            { content: '', datakey: 'dataEnvio', styles: footStyle },
            { content: 'Total', datakey: 'diaDaSemana', styles: footStyle },
            { content: Number(totalSoma('quantidade')).toString(), datakey: 'quantidade', styles: footStyle },
            { content: utils.maskCurrency(Number(totalSoma('valor'))), datakey: 'valor', styles: footStyle },
          ]]
        }
        if (dados.length === 0) {
          swal.fire({
            icon: 'warning',
            title: 'Sem dados para exportar',
            text: 'O Resultado da sua busca não retornou dados para exportação.',
            showConfirmButton: false,
            timer: 3000,
          })
          return null
        }
        return { dados, heading }
      }
      const margens = { top: 15, bottom: 20, left: 20, width: 520 }

      // eslint-disable-next-line new-cap
      const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' })
      const { dados, heading } = generateData()
      if (dados) {
        doc.text(tiposDeRelatoriosDictionary[tipoRelatorio], 20, 15)
        doc.text(periodo, 20, 22)
        doc.autoTable({
          startY: 30,
          head: heading,
          body: dados,
          showFoot: 'lastPage',
          foot: footersRows,
          theme: 'striped',
          margin: margens,
          horizontalPageBreak: true,
        })
        doc.save(`${tiposDeRelatoriosDictionary[tipoRelatorio]}.pdf`)
      } 
    }

    const comparaClientes = (_aRow, bRow, _key, sortDesc) => {
      if (bRow.clienteNome === 'Sem Cadastro') {
        if (sortDesc) {
          return -1
        }
        return 1
      }
      return null
    }

    const onFiltered = filteredItems => {
      state.currentPage = 1
      state.totalRows = filteredItems.length
    }
    const openModal = data => {
      state.modalData = data.item.relatorioLogin
      modal.value.show()
    }

    return {
      ...toRefs(state),
      onSubmit,
      getValidationState,
      openModal,
      modal,
      tiposDeRelatorioOptions,
      headerTable,
      ...table,
      ...utils,
      totalSoma,
      separaValores,
      tableRef,
      gerarExcel,
      generatePdf,
      comparaClientes,
      onFiltered,
    }
  },
}
</script>

<style scoped>
.table-footer {
    font-weight: bold;
    background: #E9E9E9;
    border-bottom: 3px solid #F55559;
    font-size: 1.2rem;
  }
</style>