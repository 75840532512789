<template>
  <div>
    <b-card class="pb-3">
      <small class="d-inline-block mb-2">
        *Após inserir um serviço e salvar, se na Web do Cliente houver conta cadastrada na integração com o Bling,<b> a geração do ShippmentID será automática</b>
      </small>
      <b-form-row class="align-items-center">
        <b-col
          md="3"
        >      
          <validation-provider
            #default="validationContext"
            name="Serviço Padrão"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Serviço Padrão"
              label-for="servicoPadrao"
            >
              <v-select
                id="servicoPadrao"
                v-model="cliente.servicoPadraoIntegracaoId"
                :reduce="val=>val.value"
                :options="servicosDisponiveis(cliente.clienteServicos ? cliente.clienteServicos.map(cs => cs.servicoId) : [])"
                :clearable="true"
                placeholder="Selecione um serviço"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Outras Transportadoras"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Outras Transportadoras"
              label-for="outras-transportadoras"
            >
              <v-select
                id="outras-transportadoras"
                v-model="cliente.transportadoraIntegracaoSomenteJadlog"
                :reduce="val=>val.value"
                :options="outrasTransportadorasOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { reactive, toRefs } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ClientesEditarComposition from '../clientes-editar.composition'

export default {
  name: 'TabDadosIntegracao',
  props: { 
    data: {
      required: true,
      type: undefined,
    },
  },
  data() {
    return {
      outrasTransportadorasOptions: [
        { label: 'Sim', value: false },
        { label: 'Não', value: true },
      ],
    }
  },
  setup() {
    const servicosStore = createNamespacedHelpers('servicos')
    const servicosState = reactive({
      servicosOptions: servicosStore.useGetters(['servicosOptions']).servicosOptions,
      dadosServico: [],
    })
    const servicosDisponiveis = ids => {
      const servList = []
      if (servicosState.servicosOptions) {
        servicosState.servicosOptions.forEach(s => {
          ids.forEach(id => {
            if (s.objValue.servicoId === id) {
              servList.push(s)
            }
          })
        })
      }
      return servList
    }
    return {
      ...toRefs(servicosState),
      ...ClientesEditarComposition(),
      avatarText,
      servicosDisponiveis,
    }
  },
}
</script>

<style lang="scss">
</style>
