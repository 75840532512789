import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import { $themeConfig } from '@themeConfig'

// Routes
// import MainPage from '@/views/main-page'
import LoginPage from '@/views/authentication/login'
import VerifyEmail from '@/views/authentication/verify-email'
import ForgotPassword from '@/views/authentication/forgot-password'
import ClientesCadastro from '@/views/cadastro/clientes/listar'
import ClientesVisualizar from '@/views/cadastro/clientes/visualizar'
import ClientesEditar from '@/views/cadastro/clientes/editar'
import DepartamentosVisualizar from '@/views/cadastro/departamentos/visualizar'
import DepartamentosEditar from '@/views/cadastro/departamentos/editar'
import PerfisCadastro from '@/views/cadastro/perfis/listar'
import PerfisVisualizar from '@/views/cadastro/perfis/visualizar'
import PerfisEditar from '@/views/cadastro/perfis/editar'
import TarifasCadastro from '@/views/cadastro/tarifas/listar'
import TaxasCadastro from '@/views/cadastro/taxas/listar'
import FaturasCadastro from '@/views/cadastro/faturas/listar'
import ImpostoCadastro from '@/views/cadastro/impostos/listar'
import ImpostoVisualizar from '@/views/cadastro/impostos/visualizar'
import TaxasEditar from '@/views/cadastro/taxas/editar'
import TarifasVisualizar from '@/views/cadastro/tarifas/visualizar'
import ColaboradoresCadastro from '@/views/cadastro/colaboradores/listar'
import ColaboradoresVisualizar from '@/views/cadastro/colaboradores/visualizar'
import ColaboradoresEditar from '@/views/cadastro/colaboradores/editar'
import RegioesCadastro from '@/views/cadastro/regioes/listar'
import RegioesVisualizar from '@/views/cadastro/regioes/visualizar'
import ImportarMovimento from '@/views/cadastro/importar-movimento'
import Afericao from '@/views/envios/afericao/index.vue'
import Overview from '@/views/dashboards/overview/index.vue'
import Relatorios from '@/views/dashboards/relatorios/index.vue'
import PesquisaEnvios from '@/views/envios/pesquisa-envios'
import Configuracoes from '@/views/envios/configuracoes'
import LogsComponent from '@core/components/logs'
import MonitorJadlog from '@/views/dashboards/monitor-jadlog'
import CurvaAbc from '@/views/dashboards/curva-abc'
import MinhaConta from '@/views/minha-conta'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', component: Overview, name: 'main-page' },
    {
      path: '/auth/login',
      component: LoginPage,
      name: 'login',
      meta: { layout: 'full' },
    },
    {
      path: '/auth/verify-email',
      component: VerifyEmail,
      name: 'verify-email',
      meta: { layout: 'full' },
    },
    {
      path: '/auth/forgot-password',
      component: ForgotPassword,
      name: 'forgot-password',
      meta: { layout: 'full' },
    },
    { path: '/afericao', component: Afericao, name: 'afericao' },
    { path: '/dashboards/overview', component: Overview, name: 'overview' },
    { path: '/dashboards/relatorios', component: Relatorios, name: 'relatorios' },
    { path: '/dashboards/monitor-api', component: MonitorJadlog, name: 'monitor-api' },
    { path: '/dashboards/curva-abc', component: CurvaAbc, name: 'curva-abc' },
    {
      path: '/pesquisa-envios',
      component: PesquisaEnvios,
      name: 'pesquisa-envios',
    },
    { path: '/configuracoes', component: Configuracoes, name: 'configuracoes' },
    {
      path: '/importar-movimento',
      component: ImportarMovimento,
      name: 'importar-movimento',
    },
    {
      path: '/cadastro/clientes',
      component: ClientesCadastro,
      name: 'clientes-cadastro',
    },
    {
      path: '/logs',
      component: LogsComponent,
      name: 'logs',
    },
    {
      path: '/cadastro/faturas',
      component: FaturasCadastro,
      name: 'faturas-cadastro',
    },
    {
      path: '/cadastro/impostos',
      component: ImpostoCadastro,
      name: 'impostos-cadastro',
    },
    {
      path: '/cadastro/impostos/:impostoId',
      component: ImpostoVisualizar,
      name: 'impostos-visualizar',
    },
    {
      path: '/cadastro/clientes/visualizar/:clienteId',
      component: ClientesVisualizar,
      name: 'clientes-visualizar',
    },
    {
      path: '/cadastro/clientes/editar/:clienteId',
      component: ClientesEditar,
      name: 'clientes-editar',
    },
    {
      path: '/cadastro/departamentos/visualizar/:departamentoId',
      component: DepartamentosVisualizar,
      name: 'departamentos-visualizar',
    },
    {
      path: '/cadastro/departamentos/editar/:departamentoId',
      component: DepartamentosEditar,
      name: 'departamentos-editar',
    },
    {
      path: '/cadastro/perfis',
      component: PerfisCadastro,
      name: 'perfis-cadastro',
    },
    {
      path: '/cadastro/perfis/visualizar/:perfilId',
      component: PerfisVisualizar,
      name: 'perfis-visualizar',
    },
    {
      path: '/cadastro/perfis/editar/:perfilId',
      component: PerfisEditar,
      name: 'perfis-editar',
    },
    {
      path: '/cadastro/tarifas',
      component: TarifasCadastro,
      name: 'tarifas-cadastro',
    },
    {
      path: '/cadastro/tarifas/visualizar/:tarifaId',
      component: TarifasVisualizar,
      name: 'tarifas-visualizar',
    },
    {
      path: '/cadastro/usuarios',
      component: ColaboradoresCadastro,
      name: 'usuarios-cadastro',
    },
    {
      path: '/cadastro/usuarios/visualizar/:usuarioId',
      component: ColaboradoresVisualizar,
      name: 'usuarios-visualizar',
    },
    {
      path: '/cadastro/usuarios/editar/:usuarioId',
      component: ColaboradoresEditar,
      name: 'usuarios-editar',
    },
    {
      path: '/cadastro/regioes',
      component: RegioesCadastro,
      name: 'regioes-cadastro',
    },
    {
      path: '/cadastro/regioes/visualizar/:regiaoImportacaoId',
      component: RegioesVisualizar,
      name: 'regioes-visualizar',
    },
    {
      path: '/cadastro/taxas',
      component: TaxasCadastro,
      name: 'taxas-cadastro',
    },
    {
      path: '/cadastro/taxas/editar/:taxaId',
      component: TaxasEditar,
      name: 'taxas-editar',
    },
    {
      path: '/minha-conta',
      component: MinhaConta,
      name: 'minha-conta',
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const { currentUser } = firebase.auth()
  const loggedIn = localStorage.getItem('loggedIn')

  if (
    currentUser
    && currentUser.emailVerified
    && to.fullPath.indexOf('/auth') >= 0
  ) {
    localStorage.setItem('loggedIn', new Date())
    return next({ name: 'main-page' })
  }
  if (
    currentUser
    && !currentUser.emailVerified
    && to.fullPath.indexOf('/auth') < 0
  ) {
    return next({ name: 'verify-email', params: null })
  }
  if (!currentUser && to.fullPath.indexOf('/auth') < 0 && !loggedIn) {
    return next({ name: 'login' })
  }
  if (to.fullPath.indexOf('/auth') >= 0) {
    return next()
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  const { appCarregando } = $themeConfig.app
  appCarregando.hide()
})

export default router