import FaturasScc4Serivice from '@/service/FaturasScc4Service'

export default {
  namespaced: true,
  state: {
    faturas: null,
    diasVencidos: null,
    quantidadeVencidos: 0,
  },
  getters: {
    getFaturas(state) {
      return state.faturas
    },
    getDiasVencidos(state) {
      return state.diasVencidos
    },
    getQuantidadeVencidos(state) {
      return state.quantidadeVencidos
    },
  },
  mutations: {
    UPDATE_FATURAS(state, faturas) {
      state.faturas = faturas
    },
    UPDATE_DIAS_VENCIDOS(state, diasVencidos) {
      state.diasVencidos = diasVencidos
    },
    UPDATE_QUANTIDADE_BOLETOS_VENCIDOS(state, quantidadeVencidos) {
      state.quantidadeVencidos = quantidadeVencidos
    },
  },
  actions: {
    async findDiasVencidos({ commit }, id) {
      const diasVencidos = await FaturasScc4Serivice.findDiasVencidos(id)
      commit('UPDATE_DIAS_VENCIDOS', diasVencidos)
    },
    async findAll({ commit }, id) {
      const faturas = await FaturasScc4Serivice.findAll(id)
      commit('UPDATE_FATURAS', faturas)
      const vencidos = faturas.filter(f => f.situacao !== 'PAGO').length
      commit('UPDATE_QUANTIDADE_BOLETOS_VENCIDOS', vencidos)
    },
  },
}
