import EmpresaService from '@/service/EmpresaService'

export default {
  namespaced: true,
  state: {
    isOnline: null,
  },
  getters: {
    isOnline(state) {
      return state.isOnline
    },
  },
  mutations: {
    UPDATE_ONLINE(state, isOnline) {
      state.isOnline = isOnline
    },
  },
  actions: {
    async verificaOnlineStatus({ commit }, empresaId) {
      const isOnline = await EmpresaService.verificaOnline(empresaId)
      commit('UPDATE_ONLINE', isOnline)
      return isOnline
    },
    async switchOnline({ commit }, empresaId) {
      const isOnline = await EmpresaService.switchOnline(empresaId)
      commit('UPDATE_ONLINE', isOnline)
      return isOnline
    },
  },
}
