<template>
  <b-sidebar
    id="add-new-dep-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Departamentos
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ validateWithInfo, handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          v-if="active"
          class="p-2"
          @submit.prevent="validaCampos(validateWithInfo), handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <h5 class="pb-1">
            Novo departamento
          </h5>

          <validation-provider
            #default="validationContext"
            name="Nome"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Nome do Departamento"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="departamento.nomeDepartamento"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Insira o nome do departamento"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Tipo de Documento"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Tipo de Documento"
              label-for="tipoDocumento"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="departamento.tipoDocumento"
                :reduce="val=>val.value"
                :options="tipoDocumentoOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="documento"
            :rules="{ required: false, noAlpha: true }"
          >
            <b-form-group
              label="CPF/CNPJ do Departamento"
              label-for="documento"
            >
              <b-form-input
                id="documento"
                :key="cleaveKey+1"
                v-model="departamento.numeroDocumento"
                v-cleave="departamento.tipoDocumento ? cleaveOptions[departamento.tipoDocumento.toLowerCase()] : cleaveOptions.cpf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Insira aqui seu CPF ou CNPJ"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="codigo"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Código de referência"
              label-for="codigo"
            >
              <b-form-input
                id="codigo"
                v-model="departamento.codigoReferencia"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Insira o código de referência"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-checkbox
            id="todos-acessam"
            v-model="departamento.todosUsuariosAcessam"
            class="my-1"
          >
            Permitir que todos os usuários deste cliente acessem este dpto.
          </b-form-checkbox>

          <validation-provider
            #default="validationContext"
            name="logo"
            rules="size:100"
          >
            <b-form-group
              label="Logo do departamento"
              label-for="logo"
            >
              <b-form-file
                id="logo"
                v-model="logoFile"
                placeholder="Selecione uma imagem"
                :state="getValidationState(validationContext)"
                rop-placeholder="Solte o arquivo aqui"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template #description>
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> A imagem a ser importada deve possuir tamanho máximo de 100kB
                </small>
              </template>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 mt-3 mb-2">
            <h5 class="mb-0">
              Endereço
            </h5>
          </div>
          
          <label 
            class="d-inline"
            for="switch-endereco"
          >
            <h5 class="d-inline">Endereço</h5>
          </label>
          <b-form-checkbox
            id="switch-endereco"
            v-model="departamento.temEndereco"
            switch
            class="d-inline ml-1 mb-2"
          />

          <div 
            v-if="departamento.temEndereco"
            class="mt-2"
          >
            <validation-provider
              v-slot="validationContext"
              name="Nome Endereço"
              rules="required"
            >
              <b-form-group
                label="Nome Endereço"
                label-for="nome-endereco"
              >
                <b-form-input
                  id="nome-endereco"
                  v-model="departamento.nomeEndereco"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Nome do endereço"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="validationContext"
              name="CEP"
              rules="required|cep"
            >
              <b-form-group
                label="CEP *"
                label-for="cep"
              >
                <b-form-input
                  id="cep"
                  v-model="departamento.cep"
                  v-cleave="cleaveOptions.cep"
                  :state="getValidationState(validationContext)"
                  placeholder="00000-000"
                  @blur="buscaEndereco()"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Logradouro"
              rules="required"
            >
              <b-form-group
                label="Logradouro *"
                label-for="logradouro"
              >
                <b-form-input
                  id="logradouro"
                  v-model="departamento.logradouro"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Avenida Brasil"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Número"
              rules="noAlpha|noSpecial"
            >
              <b-form-group
                label="Número"
                label-for="numero"
              >
                <b-form-input
                  id="numero"
                  v-model="departamento.numero"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="100"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Complemento"
            >
              <b-form-group
                label="Complemento"
                label-for="complemento"
              >
                <b-form-input
                  id="complemento"
                  v-model="departamento.complemento"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Bloco B - Sala 210"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Bairro"
              rules="required"
            >
              <b-form-group
                label="Bairro *"
                label-for="bairro"
              >
                <b-form-input
                  id="bairro"
                  v-model="departamento.bairro"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Centro"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Cidade"
              rules="required"
            >
              <b-form-group
                label="Cidade *"
                label-for="cidade"
              >
                <b-form-input
                  id="cidade"
                  v-model="departamento.cidade"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="São Paulo"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="UF"
              rules="required"
            >
              <b-form-group
                label="UF *"
                label-for="uf"
              >
                <b-form-input
                  id="uf"
                  v-model="departamento.uf"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="SP"
                  readonly
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-3 mb-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar departamento
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import CepService from '@/service/CepService'
import cleaveOptions from '@/libs/cleave/options'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import _ from 'lodash'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'DepartamentoAddSidebar',
  props: {
    clienteId: {
      required: true,
      type: undefined,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankDepartamento = {
      clienteId: props.clienteId,
      codigoReferencia: '',
      nomeDepartamento: '',
      tipoDocumento: '',
      numeroDocumento: null,
      ativo: true,
      temEndereco: null,
      nomeEndereco: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      cep: '',
      longitude: '',
      latitude: '',
      urlLogo: null,
      todosUsuariosAcessam: '',
    }
    const state = reactive({ 
      departamento: JSON.parse(JSON.stringify(blankDepartamento)),
      logoFile: null,
    })
    const cleaveKey = ref(0)
    const resetDepartamento = () => {
      state.departamento = JSON.parse(JSON.stringify(blankDepartamento))
    }

    const tipoDocumentoOptions = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' },
    ]

    const appLoading = $themeConfig.app.appLoadingImage

    const onSubmit = () => {
      appLoading.show()
      const multipart = new FormData()
      multipart.append('logoFile', state.logoFile)
      multipart.append('departamento', JSON.stringify(
        { 
          ...state.departamento,
          numeroDocumento: state.departamento.numeroDocumento ? state.departamento.numeroDocumento.replaceAll(/[.,()/\s-]+/g, '') : '',
          cep: state.departamento.cep ? state.departamento.cep.replaceAll(/[.,()/\s-]+/g, '') : '',
        },
      ))
      store.dispatch('departamentos/save', multipart)
        .then(() => {
          appLoading.hide()
          emit('refresh')
          emit('update:active', false)
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Departamento inserido com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        })
    }
    function incrementCleaveKey() {
      cleaveKey.value += 1
    }

    watch(() => state.departamento.tipoDocumento, () => {
      incrementCleaveKey()
    })

    const buscaEndereco = () => {
      CepService.buscarEndereco(state.departamento.cep).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            dadosEndereco.cep = state.departamento.cep
            state.departamento = { ...state.departamento, ...dadosEndereco }
          }
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDepartamento)

    return {
      ...toRefs(state),
      onSubmit,
      tipoDocumentoOptions,
      cleaveKey,
      cleaveOptions: _.cloneDeep(cleaveOptions),
      //  Functions
      buscaEndereco,
      //  formValidation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    validaCampos(validateWithInfo) {
      validateWithInfo().then(validate => {
        if (!validate.isValid) {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Há erros no formulário. Verifique!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
