const dadosApi = [
  {
    label: 'Dados da Api',
    info: [
      { text: 'Insira o CFOP mais comum em seus produtos', value: 'CFOP-NFE' },
    ],
    text: 'CFOP-NFE',
  },
  {
    label: 'Dados da Api',
    info: [
      { text: 'Solicitação Eletrônica (Aguardando remessa física)', value: 'S' },
      { text: 'Solicitação de coleta no remetente', value: 'K' },
    ],
    text: 'Tipo da Coleta',
  },
  { 
    label: 'Dados da Api',
    info: [
      { text: 'Irá enviar o peso para a API no momento da geração da etiqueta', value: 'Sim' },
      { text: 'Não enviará o peso', value: 'Não' },
    ],
    text: 'Enviar Peso',
  },
  {
    label: 'Dados da Api',
    info: [
      { text: 'Irá enviar as dimensões para a API no momento da geração da etiqueta', value: 'Sim' },
      { text: 'Não enviará as dimensões', value: 'Não' },
    ],
    text: 'Enviar Dimensões',
  },
  { 
    label: 'Dados da Api',
    info: [
      { text: 'A cotação de frete passará a ser feita diretamente na API', value: 'Sim' },
      { text: 'A cotação de frete se baseará nas tabelas de tarifas inseridas no sistema e vinculadas no serviço do cliente', value: 'Não' },
    ],
    text: 'Cotação de frete via API',
  },
  { 
    label: 'Dados da Api',
    info: [
      { text: 'Usuário utilizado para logar no Portal do Cliente Jadlog.', value: 'Usuário Jadlog' },
    ],
    text: 'Usuário para Tratativa',
  },
]

const servicos = [
  { 
    label: 'Serviços',
    info: [
      { text: 'Contendo taxa cadastrada, ao selecionar nesse campo, será somada ao resultado da cotação de frete quando essa for via API', value: '' },
    ],
    text: 'Taxas Cotação de Frete API',
  },
  { 
    label: 'Serviços',
    info: [
      { text: 'Será utilizado na cotação de frete para chegar no peso cúbido.', value: '' },
    ],
    text: 'Fator Cubagem',
  },
  { 
    label: 'Serviços',
    info: [
      { text: 'Os serviços selecionados serão habilitados para a geração de etiqueta e cotação de frete via API.', value: '' },
    ],
    text: 'Ativo',
  },
  { 
    label: 'Serviços',
    info: [
      { text: 'Após tabelas de tarifas cadastradas, elas aparecem nesse campo para ser selecionada. Vinculando a tabela, a cotação de frete passa a se basear na tabela, isso se na aba Dados da API não estiver marcado que a cotação é via API.', value: '' },
    ],
    text: 'Tarifa',
  },
  { 
    label: 'Serviços',
    info: [
      { text: 'Possibilita ser inserido taxa de valor fixo por serviço. Essa taxa será somada ao resultado da cotação de frete.', value: '' },
    ],
    text: 'Taxa Fixa',
  },
]

const dadosEmail = [
  {
    label: 'Conf. E-mail',
    info: [
      { text: 'O serviço selecionado será utilizado como padrão na geração de todas as etiquetas no qual o arquivo é recebido por e-mail', value: 'Serviço Padrão' },
    ],
    text: 'Serviço Padrão',
  },
  { 
    label: 'Conf. E-mail',
    info: [
      { text: 'Será gerado etiqueta de arquivos contendo qualquer transportadora como tranportador', value: 'Sim' },
      { text: 'Será gerado etiqueta somente de arquivos que conter Jadlog como transportador', value: 'Não' },
    ],
    text: 'Outras Transportadoras',
  },
]

const confFtp = [
  {
    label: 'Conf. FTP',
    info: [
      { text: 'Serão lidos apenas arquivos com a palavra iniciando com esse valor', value: 'Nome inicial arquivos' },
    ],
    text: 'Nome inicial arquivos',
  },
]

const InfoLegenda = { dadosApi, servicos, dadosEmail, confFtp }

export default InfoLegenda