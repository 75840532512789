<template>
  <div>
    <b-card
      no-body
      class="text-center"
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <div class="d-flex align-items-center justify-content-start">
              <b class="title">On-line / Off-line</b>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <b class="subtitle">Liberar geração de etiquetas</b>
            <div
              v-if="online"
              class="m-2"
            >
              <p>Essa Ação fará com que as etiquetas impressas na Web do Clientes <b>não constem ShippmentID</b></p>
              <b-row align-v="center">
                <b-col md="3">
                  <b-button
                    variant="primary"
                    class="mb-2 mb-md-0"
                    type="button"
                    @click="confirmarOffline = true"
                  >
                    Ativar Modo Off-line
                  </b-button>
                </b-col>
                <b-col
                  v-if="confirmarOffline"
                  md="4"
                  align-self="stretch"
                >
                  <p class="text-red mb-0">
                    Deseja realmente gerar etiquetas sem o ShippmentID?
                  </p>
                </b-col>
                <b-col
                  v-if="confirmarOffline"
                  md="5"
                >
                  <b-button
                    variant="primary"
                    class="mr-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="switchOnline"
                  >
                    Sim
                  </b-button>
                  <b-button
                    variant="secondary"
                    class="mr-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="confirmarOffline = false"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div
              v-else
              class="m-2"
            >
              <p>Essa Ação fará com que as etiquetas impressas na Web do Clientes <b>constem ShippmentID</b></p>
              <b-row align-v="center">
                <b-col cols="3">
                  <b-button
                    variant="danger"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="confirmarOnline = true"
                  >
                    Ativar Modo On-line
                  </b-button>
                </b-col>
                <b-col
                  v-if="confirmarOnline"
                  cols="4"
                  align-self="stretch"
                >
                  <p class="text-red mb-0">
                    Deseja realmente gerar etiquetas com o ShippmentID?
                  </p>
                </b-col>
                <b-col
                  v-if="confirmarOnline"
                  cols="5"
                >
                  <b-button
                    variant="primary"
                    class="mr-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="switchOnline"
                  >
                    Sim
                  </b-button>
                  <b-button
                    variant="secondary"
                    class="mr-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="confirmarOnline = false"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import store from '@/store'

export default {
  name: 'Configuracoes',
  data() {
    return {
      confirmarOffline: false,
      confirmarOnline: false,
    }
  },
  computed: {
    online() {
      return store.getters['empresa/isOnline']
    },
    empresaId() {
      return JSON.parse(window.localStorage.getItem('userName')).empresaId
    },
  },
  created() {
    store.dispatch('empresa/verificaOnlineStatus', this.empresaId)
  },
  methods: {
    switchOnline() {
      this.confirmarOffline = false
      this.confirmarOnline = false
      //   this.online = !this.online
      store.dispatch('empresa/switchOnline', this.empresaId)
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 1.7rem;
}
.subtitle {
    font-size: 1.5rem;
}

.text-red {
    color: red;
}
</style>