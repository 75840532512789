<template>
  <div>
    <b-tabs
      v-if="cliente"
      v-model="tabIndex"
      pills
    >
      <b-media 
        v-if="tabIndex <= 7"
        class="my-2"
      >
        <template #aside>
          <div v-if="cliente.urlLogo">
            <img
              :src="cliente.urlLogo"
              alt="logo"
              class="logoCliente"
            >
            <p
              class="text-primary text-center excluir-logo"
              @click="apagarLogo"
            >
              Excluir Logo
            </p>
          </div>
          <b-avatar
            v-else
            ref="previewEl"
            :text="avatarText(cliente.nomeFantasia)"
            variant="light-success"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ cliente.nomeFantasia }}
        </h4>
        <h5 class="mb-1">
          {{ cliente.email }}
        </h5>
        <h5 class="mb-1">
          {{ cliente.telefone }}
        </h5>
      </b-media>
      <validation-observer
        #default="{ validateWithInfo, handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="mb-4"
          @submit.prevent="validaCampos(validateWithInfo), handleSubmit(salvarCliente)"
        >
          <!-- Tab: Account -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Geral</span>
            </template>
            <!--Tab de Informações Gerais-->
            <tab-geral :data="cliente"/>
          </b-tab>
          <!-- Tab de Endereço -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="MapPinIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Endereço</span>
            </template>
            <tab-endereco
              :data.sync="cliente"
              :tab-index.sync="tabIndex"
            />
          </b-tab>
          <!-- Tab de Dados da API -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="CpuIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Dados da API</span>
            </template>
            <tab-dados :data="cliente"/>
          </b-tab>
          <!-- Tab de Dados do Fraction -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="SidebarIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Dados do Fraction</span>
            </template>
            <tab-dados-fraction :data="cliente"/>
          </b-tab>
          <!-- Tab de Serviços -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="BookmarkIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Serviços</span>
            </template>
            <tab-servicos :data="cliente"/>
          </b-tab>
          <!-- Tab de Configuração de E-mail -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="MailIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Conf. E-mail</span>
            </template>
            <tab-dados-email :data="cliente"/>
          </b-tab>
          <!-- Tab de Configuração de Integração -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="PackageIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Conf. Integrações</span>
            </template>
            <tab-dados-integracao :data="cliente"/>
          </b-tab>
          <!-- Tab Departamentos -->
          <b-tab v-if="mostrarDepartamentos">
            <template #title>
              <feather-icon
                icon="SidebarIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Departamentos</span>
            </template>
            <tab-departamentos
              :cliente-id.sync="$route.params.clienteId"
            />
          </b-tab>
          <!-- Tab Conf.FTP -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="FolderIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Conf. FTP</span>
            </template>
            <tab-dados-ftp :data="cliente"/>
          </b-tab>
          <!-- Tab Usuários -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="UserCheckIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Usuários</span>
            </template>
            <tab-users
              :id-cliente="$route.params.clienteId"
            />
          </b-tab>
          <div 
            v-if="tabIndex <= 7"
          >
            <b-button
              type="submit"
              variant="primary"
              class="my-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Salvar Mudanças
              <feather-icon
                icon="SaveIcon"
                class="ml-1"
              />
            </b-button>
            <b-button
              variant="outline-secondary"
              class="mr-sm-1 mr-0 my-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="$router.go(-1)"
            >
              Cancelar Alterações
              <feather-icon
                icon="RefreshCwIcon"
                class="ml-1"
              />
            </b-button>
            <span v-if="currentUser.clienteId != cliente.clienteId">
              <b-button
                v-if="cliente.ativo === true"
                variant="danger"
                class="mr-sm-1 mr-0 my-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="switchClienteAtivo()"
              >
                Inativar
                <feather-icon
                  icon="TrashIcon"
                  class="ml-1"
                />
              </b-button>
              <b-button
                v-else
                variant="primary"
                class="mr-sm-1 mr-0 my-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="switchClienteAtivo()"
              >
                Ativar
                <feather-icon
                  icon="CheckIcon"
                  class="ml-1"
                />
              </b-button>
            </span>
            
          </div>
        </b-form>
      </validation-observer>
    </b-tabs>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { mapGetters } from 'vuex'
import utils from '@/helpers/utils'
import { $themeConfig } from '@themeConfig'
import InfoLegenda from '@/@core/utils/infoLegenda'
import ClientesEditarComposition from './clientes-editar.composition'
import TabGeral from './tabs/geral'
import TabEndereco from './tabs/endereco'
import TabServicos from './tabs/servicos'
import TabDados from './tabs/dadosDaApi.vue'
import TabDadosFraction from './tabs/dadosDoFraction.vue'
import TabDadosEmail from './tabs/dadosEmail.vue'
import TabDadosIntegracao from './tabs/dadosIntegracao.vue'
import TabUsers from './tabs/users.vue'
import TabDepartamentos from './tabs/departamentos.vue'
import TabDadosFtp from './tabs/dadosFtp.vue'

const { appCarregando } = $themeConfig.app

export default {
  name: 'ClientesEditar',
  components: {
    TabGeral,
    TabEndereco,
    TabServicos,
    TabDados,
    TabDadosFraction,
    TabDadosEmail,
    TabDadosIntegracao,
    TabDepartamentos,
    TabUsers,
    TabDadosFtp,
  },
  data() {
    return {
      tabIndex: 8,
      mostrarDepartamentos: false,
    }
  },
  computed: {
    ...mapGetters('clienteFtp', ['getFtpsExterno', 'getFtpsPortal']),
  },
  watch: {
    tabIndex(tabIndex) {
      this.ajusteLegenda(tabIndex)
    },
  },
  beforeMount() {
    store.dispatch('clientes/findById', this.$route.params.clienteId)
    store.dispatch('clienteFtp/findByClienteId', this.$route.params.clienteId)
    store.dispatch('departamentos/findAllByClienteId', this.$route.params.clienteId)
      .then(departamentos => store.dispatch('departamentos/setOptions', departamentos))
  },
  created() {
    const routeTabIndex = Number(this.$route.query.tabindex)
    if (routeTabIndex) { this.tabIndex = routeTabIndex }
  },
  destroyed() {
    store.dispatch('infoSideBar/resetLegenda')
    store.dispatch('clientes/setSelected', [])
    store.dispatch('clienteFtp/resetValues')
  },
  methods: {
    validaCampos(validateWithInfo) {
      const tabs = { 'tab-geral': 0, 'tab-endereco': 1, 'tab-dados': 2, 'tab-dados-fraction': 3, 'tab-servicos': 4, 'tab-dados-email': 5, 'tab-dados-integracao': 6, 'tab-dados-ftp': 7, 'tab-users': 8 }
      validateWithInfo().then(validate => {
        if (!validate.isValid) {
          const { name } = Object.values(validate.fields).find(f => f.passed === false)
          if (name) {
            // eslint-disable-next-line no-underscore-dangle
            this.tabIndex = tabs[this.$refs.refFormObserver.refs[`${name}`].$parent.$options._componentTag]
          }
        }
      })
    },
    salvarCliente() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Deseja salvar as alterações?',
        showCancelButton: true,
        confirmButtonText: 'Sim, eu desejo',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appCarregando.show()
          const clienteFtp = []
          const ftpExterno = this.getFtpsExterno
          const ftpPortal = this.getFtpsPortal
          if (Object.keys(ftpExterno).length > 4) {
            ftpExterno.clienteId = this.cliente.clienteId
            clienteFtp.push(ftpExterno)
          }
          if (Object.keys(ftpPortal).length > 4) {
            ftpPortal.clienteId = this.cliente.clienteId
            clienteFtp.push(ftpPortal)
          }
          const multipart = new FormData()
          multipart.append('logoFile', this.cliente.logoFile)
          multipart.append('clienteString', JSON.stringify({
            ...this.cliente,
            numeroDocumento: this.cliente.numeroDocumento ? this.cliente.numeroDocumento.replaceAll(/[.,()/\s-]+/g, '') : '',
            telefone: this.cliente.telefone.replaceAll(/[.,()/\s-]+/g, ''),
            cep: this.cliente.cep ? this.cliente.cep.replaceAll(/[.,()/\s-]+/g, '') : '',
            clienteFtp,
          }))
          this.$store.dispatch('clientes/edit', {
            clienteId: this.cliente.clienteId,
            multipart,
          }).then(data => {
            appCarregando.hide()
            if (data.success) {
              this.$swal({
                icon: 'success',
                title: 'Parabéns!',
                text: 'Você alterou os dados do cliente!',
                showConfirmButton: false,
                timer: 2000,
              })
              this.resetForm()
            } else {
              this.$swal({
                icon: 'warning',
                title: 'Atenção!',
                text: `${data.body}`,
                showConfirmButton: true,
              })
            }
          })
        }
      })
    },
    switchClienteAtivo() {
      this.$swal({
        title: `${this.cliente.ativo ? 'DESATIVAR CLIENTE?' : 'ATIVAR CLIENTE?'}`,
        icon: 'warning',
        html: `${this.cliente.ativo ? 'Ao desativar esse cliente ele não acessará mais o site e não será possível realizar postagens para o mesmo. Tem certeza que deseja desativar o cliente?' : 'Deseja mesmo ativar o cliente?'}`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appCarregando.show()
          this.handleAtivo().then(() => {
            appCarregando.hide()
            this.$swal({
              icon: 'success',
              html: '<h1>Alterado com sucesso!</h1>',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }).then(() => {
            this.$router.push('/cadastro/clientes')
          })
        }
      })
    },
  },
  setup() {
    const currentUser = JSON.parse(localStorage.getItem('userName'))
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 1)[0]

    const ajusteLegenda = tabIndex => {
      if (tabIndex === 2) {
        store.dispatch('infoSideBar/setLegenda', InfoLegenda.dadosApi)
      } else if (tabIndex === 4) {
        store.dispatch('infoSideBar/setLegenda', InfoLegenda.servicos)
      } else if (tabIndex === 5) {
        store.dispatch('infoSideBar/setLegenda', InfoLegenda.dadosEmail)
      } else if (tabIndex === 7) {
        store.dispatch('infoSideBar/setLegenda', InfoLegenda.confFtp)
      } else {
        store.dispatch('infoSideBar/resetLegenda')
      }
    }

    return {
      ...ClientesEditarComposition(),
      avatarText,
      ...utils,
      currentUser,
      ajusteLegenda,
      permissoes,
    }
  },
}
</script>

<style scoped>
.logoCliente {
  max-width: 150px;
  max-height: 150px;
}

.log-tab {
  position: sticky;
  top: 0;
  z-index: 999;
}

.excluir-logo {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
}

.remove-b {
  background-color: transparent;
  
  font-size: 0.9rem;
  line-height: 0.9rem;
  color: #5e5873;
  border: none;
  padding: 0;
  border-radius: 0;
  appearance: none;
}
</style>