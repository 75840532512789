<template>
  <div>
    <departamento-add-sidebar
      :cliente-id.sync="clienteId"
      :active.sync="departamentoAddSidebarActive"
      @refresh="refresh(clienteId)"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mt-2 mb-0"
    >
      <h1 class="m-2 mt-2">
        Lista de Departamentos
      </h1>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>departamentos por página</label>
          </b-col>
          <b-col 
            cols="12"
            md="4"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="permissoes.editar"
              variant="primary"
              @click="departamentoAddSidebarActive = true"
            >
              <span class="text-nowrap">Adicionar Departamento</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        class="position-relative"
        :items="departamentos"
        :fields="tableColumns"
        :filter="search"
        :current-page="currentPage"
        :per-page="perPage"
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Busca sem resultados"
        primary-key="clienteDepartamentoId"
        :sort-by.sync="sortBy"
        show-empty
        stacked="md"
        :sort-desc.sync="isSortDirDesc"
        @filtered="onFiltered"
      >
        <template #cell(check)="data">
          <b-form-checkbox
            v-model="data.item.selecionado"
            @change="selecionaDepartamento($event, data.item.clienteDepartamentoId)"
          />
        </template>
        <template 
          v-if="permissoes.editar || permissoes.visualizar || permissoes.excluir" 
          #cell(acoes)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item 
              v-if="permissoes.visualizar"
              :to="{ name: 'departamentos-visualizar', params: { departamentoId: data.item.clienteDepartamentoId } }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>

            <b-dropdown-item 
              v-if="permissoes.editar"
              :to="{ name: 'departamentos-editar', params: { departamentoId: data.item.clienteDepartamentoId, clienteId: clienteId } }"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="permissoes.excluir"
              @click="removeDepartamentos(data.item.clienteDepartamentoId)"
            >
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Deletar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template 
          v-else
          #cell(acoes)=""
        >
          <span title="Nenhuma ação permitida">
            <feather-icon icon="SlashIcon"/>
          </span>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <div class="mx-2 mb-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="mt-2 d-flex align-items-left justify-content-start justify-content-sm-start"
          >
            <b-button
              v-if="permissoes.excluir"
              variant="danger"
              @click="removeDepartamentos(null)"
            >
              <span class="text-nowrap">Remover Departamentos</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import store from '@/store'
import { reactive, toRefs } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import DepartamentosComposition from '../../departamentos.composition'
import DepartamentoAddSidebar from '../../components/add-departamento-sidebar'

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'TabDepartamentos',
  components: { DepartamentoAddSidebar },
  props: {
    clienteId: {
      required: true,
      type: undefined,
    },
  },
  setup() {
    const state = reactive({
      ids: [],
    })

    const selecionaDepartamento = (ev, id) => {
      if (ev) {
        state.ids.push(id)
      } else {
        const index = state.ids.indexOf(id)
        state.ids.splice(index, 1)
      }
    }

    const removeDepartamentos = depId => {
      if (depId) {
        state.ids = []
        state.ids.push(depId)
      }
      if (state.ids.length > 0) {
        swal.fire({
          title: 'Atenção!',
          icon: 'warning',
          html: 'Você tem certeza que quer deletar os departamentos?',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showCancelButton: true,
          cancelButtonText: 'Não, calma aí.',
          confirmButtonText: 'Sim, eu tenho!',
          buttonsStyling: false,
        }).then(result => {
          let cont = 0
          if (result.value) {
            appLoading.show()
            const promises = []
            state.ids.forEach(id => {
              promises.push(new Promise(resolve => {
                store.dispatch('departamentos/delete', id)
                  .then(response => {
                    if (response.success) {
                      cont += 1
                    }
                    resolve()
                  })
              }))
            })
            Promise.all(promises)
              .then(() => {
                state.ids = []
                appLoading.hide()
                swal.fire({
                  icon: 'success',
                  html: `<h1>${cont} departamento(s) deletado(s) com sucesso!</h1>`,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
      } else {
        swal.fire({
          icon: 'info',
          html: 'Nenhum departamento selecionado',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    }

    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 1)[0]

    return {
      ...toRefs(state),
      ...DepartamentosComposition(),
      selecionaDepartamento,
      removeDepartamentos,
      permissoes,
    }
  },
}
</script>

<style lang="scss">
.checkbox-group div {
  margin-bottom: 20px;
}
.select-tarifa {
  width: 300px;
}
</style>