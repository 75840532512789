<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Razão Social"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Razão Social"
              label-for="razaoSocial"
            >
              <b-form-input
                id="razao-social"
                v-model="cliente.razaoSocial"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Razão Social"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Nome Fantasia"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Nome Fantasia"
              label-for="nomeFantasia"
            >
              <b-form-input
                id="nomeFantasia"
                v-model="cliente.nomeFantasia"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nome Fantasia"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="cliente.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="cliente@dominio.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Telefone"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                v-model="cliente.telefone"
                v-cleave="cleaveOptions.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="(00) 00000-0000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Tipo de Documento"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Tipo de Documento"
              label-for="tipoDocumento"
            >
              <v-select
                v-model="cliente.tipoDocumento"
                :reduce="val=>val.value"
                :options="tipoDocumentoOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Documento"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Documento"
              label-for="numeroDocumento"
            >
              <b-form-input
                id="numeroDocumento"
                :key="cleaveKey"
                v-model="cliente.numeroDocumento"
                v-cleave="cliente.tipoDocumento ? cleaveOptions[cliente.tipoDocumento.toLowerCase()] : 
                  cleaveOptions.cpf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Documento"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Inscrição Estadual"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Inscrição Estadual"
              label-for="inscricaoEstadual"
            >
              <b-form-input
                id="inscricaoEstadual"
                v-model="cliente.inscricaoEstadual"
                :state="getValidationState(validationContext)"
                trim
                placeholder="000000000"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="logo"
            rules="size:100"
          >
            <b-form-group
              label="Logo do cliente"
              label-for="logo"
            >
              <b-form-file
                id="logo"
                v-model="cliente.logoFile"
                placeholder="Selecione uma imagem"
                :state="getValidationState(validationContext)"
                rop-placeholder="Solte o arquivo aqui"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template #description>
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> A imagem deve possuir tamanho máximo de 100kB
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h5 class="mb-2">
        Midia Social para Tela de Rastreio
      </h5>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlFacebook"
            rules="url-face"
          >
            <b-form-group
              label="Url Facebook"
              label-for="urlFacebook"
            >
              <b-form-input
                id="url-facebook"
                v-model="cliente.urlFacebook"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.facebook.com/"
                :disabled="desativarUrls('facebook')"
                @blur="prependHttps('urlFacebook', 'facebook')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('facebook')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlYoutube"
            rules="url-you"
          >
            <b-form-group
              label="Url YouTube"
              label-for="urlYoutube"
            >
              <b-form-input
                id="url-youtube"
                v-model="cliente.urlYoutube"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.youtube.com/"
                :disabled="desativarUrls('youtube')"
                @blur="prependHttps('urlYoutube', 'youtube')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('youtube')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlX"
            rules="url-twitter"
          >
            <b-form-group
              label="Url X"
              label-for="urlX"
            >
              <b-form-input
                id="url-x"
                v-model="cliente.urlX"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://twitter.com/"
                :disabled="desativarUrls('twitter')"
                @blur="prependHttps('urlX', 'twitter')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('twitter')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlInstagram"
            rules="url-insta"
          >
            <b-form-group
              label="Url Instagram"
              label-for="urlInstagram"
            >
              <b-form-input
                id="url-instagram"
                v-model="cliente.urlInstagram"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.instagram.com/"
                :disabled="desativarUrls('instagram')"
                @blur="prependHttps('urlInstagram', 'instagram')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('instagram')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlPinterest"
            rules="url-pint"
          >
            <b-form-group
              label="Url Pinterest"
              label-for="urlPinterest"
            >
              <b-form-input
                id="url-pinterest"
                v-model="cliente.urlPinterest"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://br.pinterest.com/"
                :disabled="desativarUrls('pinterest')"
                @blur="prependHttps('urlPinterest', 'pinterest')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('pinterest')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlTiktok"
            rules="url-tik"
          >
            <b-form-group
              label="Url TikTok"
              label-for="urlTiktok"
            >
              <b-form-input
                id="url-tiktok"
                v-model="cliente.urlTiktok"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.tiktok.com/"
                :disabled="desativarUrls('tiktok')"
                @blur="prependHttps('urlTiktok', 'tiktok')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('tiktok')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlLinkedin"
            rules="url-link"
          >
            <b-form-group
              label="Url LinkedIn"
              label-for="urlLinkedin"
            >
              <b-form-input
                id="url-linkedin"
                v-model="cliente.urlLinkedin"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.linkedin.com/"
                :disabled="desativarUrls('linkedin')"
                @blur="prependHttps('urlLinkedin', 'linkedin')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('linkedin')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import utils from '@/helpers/utils'
import { onMounted, reactive, toRefs } from '@vue/composition-api'
import ClientesEditarComposition from '../clientes-editar.composition'

export default {
  name: 'TabGeral',
  props: {
    data: {
      required: true,
      type: undefined,
    },
  },
  setup(props) {
    const tipoDocumentoOptions = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' },
    ]

    const state = reactive({
      urlAtivas: [],
      faceValid: false,
    })

    const contUrlAtivas = (val, local) => {
      if (val === null || val === '') {
        const index = state.urlAtivas.indexOf(local)
        if (index !== -1) {
          state.urlAtivas.splice(index, 1)
        }
      } else if (!state.urlAtivas.includes(local)) {
        state.urlAtivas.push(local)
      }
    }

    const prependHttps = (platform, midia) => {
      const value = props.data[platform]
      if (value !== null && value !== '' && !value.includes('https://')) {
        // eslint-disable-next-line no-param-reassign
        props.data[platform] = `https://${props.data[platform]}`
      }
      contUrlAtivas(props.data[platform], midia)
    }

    onMounted(() => {
      if (props.data.urlFacebook != null && props.data.urlFacebook !== '') {
        contUrlAtivas('urlFacebook', 'facebook')
      }
      if (props.data.urlInstagram != null && props.data.urlInstagram !== '') {
        contUrlAtivas('urlInstagram', 'instagram')
      }
      if (props.data.urlLinkedin != null && props.data.urlLinkedin !== '') {
        contUrlAtivas('urlLinkedin', 'linkedin')
      }
      if (props.data.urlYoutube != null && props.data.urlYoutube !== '') {
        contUrlAtivas('urlYoutube', 'youtube')
      }
      if (props.data.urlPinterest != null && props.data.urlPinterest !== '') {
        contUrlAtivas('urlPinterest', 'pinterest')
      }
      if (props.data.urlTiktok != null && props.data.urlTiktok !== '') {
        contUrlAtivas('urlTiktok', 'tiktok')
      }
      if (props.data.urlX != null && props.data.urlX !== '') {
        contUrlAtivas('urlX', 'twitter')
      }
    })

    const desativarUrls = val => {
      if (state.urlAtivas.length > 4) {
        return !state.urlAtivas.includes(val)
      } return false
    }

    return {
      ...ClientesEditarComposition(),
      ...toRefs(state),
      avatarText,
      // Enums
      tipoDocumentoOptions,
      // Methods
      desativarUrls,
      prependHttps,
      ...utils,
    }
  },
}
</script>

<style scoped>

</style>
