import store from '@/store'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default () => {
  const departamentosStore = createNamespacedHelpers('departamentos')
  const state = reactive({
    departamentos: departamentosStore.useGetters(['departamentos']).departamentos,
    ids: [],
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => state.departamentos, value => {
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })
  
  const tableColumns = [
    { key: 'check', sortable: false },
    { key: 'clienteDepartamentoId', sortable: true, label: 'JAD Code' },
    { key: 'codigoReferencia', sortable: true, label: 'COD.REFERÊNCIA' },
    { key: 'nomeDepartamento', sortable: true, label: 'Departamento' },
    { key: 'acoes', label: 'Ações' },
  ]

  const search = ref('')
  const sortBy = ref('clienteDepartamentoId')
  const isSortDirDesc = ref(false)
  const departamentoAddSidebarActive = ref(false)

  const refresh = clienteId => {
    state.currentPage = 1
    search.value = ''
    store.dispatch('departamentos/findAllByClienteId', clienteId)
      .then(response => {
        state.departamentos.value = response
      })
  }

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }

  return {
    ...toRefs(state),
    tableColumns,
    search,
    sortBy,
    isSortDirDesc,
    departamentoAddSidebarActive,
    // funções
    refresh,
    onFiltered,
  }
}
